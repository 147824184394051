/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Card from "react-bootstrap/Card";
import Carousel from "react-bootstrap/Carousel";
import { isEmpty, isNil } from "ramda";

import { COLORS } from "../config";
import { Link } from "react-router-dom";
import Loader from "./Loader";
import { take } from "ramda";

const Highlights = (props) => {
  let colorsUsed = [];

  function shuffler(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  const changeColor = () => {
    let choosenColor = {};

    const shuffled = shuffler(COLORS);

    for (let index = 0; index < shuffled.length; index++) {
      const item = shuffled[index];

      if (!colorsUsed.includes(item.backgroundColor)) {
        colorsUsed.push(item.backgroundColor);
        choosenColor = item;
        break;
      }
    }

    return choosenColor;
  };

  const { lowerCarItems } = props;

  // const splited = compact(
  //   lowerCarItems.map((e, i) => {
  //     return i % (isMobile ? 1 : 4) === 0
  //       ? lowerCarItems.slice(i, i + (isMobile ? 1 : 4))
  //       : null;
  //   })
  // );

  const items = take(4, shuffler(lowerCarItems));

  if (!isEmpty(items) && !isNil(items)) {
    return (
      <div className="p-0">
        <div className="row">
          <div className="col">
            <h2 className="txt-brown text-uppercase mt-3 mb-3">Destaques</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 m-0 p-0">
            <Carousel controls={false} indicators={false}>
              {/* {splited.map((itemP) => { */}
              {/* return ( */}
              <Carousel.Item key={Math.random()}>
                <div className="card-group col-12" key={Math.random()}>
                  {items.map((item) => {
                    let cColor = changeColor();
                    return (
                      <Card key={item.id}>
                        {/* <Card.Img
                          variant="top"
                          className="card-img-sameh item"
                          src={
                            item.exp_url_image
                              ? item.exp_url_image
                              : item.first_image
                          }
                        /> */}

                        <div
                          className="card-img-sameh img-fluid"
                          style={{
                            backgroundImage: `url(${
                              !!item.image
                                ? item.image
                                : item.exp_url_image
                                ? item.exp_url_image
                                : item.first_image
                            })`,
                            backgroundPosition: "center center fixed",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "100% auto",
                            maxHeight: "100px",
                          }}
                        ></div>

                        <Card.Body
                          style={{ backgroundColor: cColor.backgroundColor }}
                        >
                          <Card.Title>
                            <Link
                              to={item.url}
                              className="fas fa-angle-right"
                              style={{ color: `${cColor.color} !impoprtant` }}
                            >
                              {item.small_description
                                ? item.small_description
                                : item.description}
                            </Link>
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    );
                  })}
                </div>
              </Carousel.Item>
              {/* );
              })} */}
            </Carousel>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="d-flex justify-content-center">
        <Loader isLoading={!items} />
      </div>
    );
  }
};

export default Highlights;
