/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { API_PATH } from "../config";

function getAllLearnignAreas() {
  return axios.get(`${API_PATH}v1/learning_areas`);
}

function getAllQuizThemes() {
  return axios.get(`${API_PATH}v1/themes`);
}

function getAllQuizSubThemes() {
  return axios.get(`${API_PATH}v1/sub_themes`);
}

function getAllQuizzTypes() {
  return axios.get(`${API_PATH}v1/quiz_types`);
}

function getAllQuizzSubTypes() {
  return axios.get(`${API_PATH}v1/quiz_sub_types`);
}

function getAllYears() {
  return axios.get(`${API_PATH}v1/years`);
}

function getAllQuizzesBypage(page = 1, year = '', theme = '', sub_theme = '', test_type = '') {
  return axios.get(`${API_PATH}v1/labs/?cur_page=${page}&year=${year}&theme=${theme}&sub_theme=${sub_theme}&test_type=${test_type}`);
}

async function getAllData() {
  const labsData = await getAllQuizzesBypage();
  const learningAreasData = await getAllLearnignAreas();
  const quizThemesData = await getAllQuizThemes();
  const quizSubThemesData = await getAllQuizSubThemes();
  const quizTypesData = await getAllQuizzTypes();
  const quizSubTypesData = await getAllQuizzSubTypes();
  const yearsData = await getAllYears();

  return {
    quizzes: labsData.data,
    learningAreas: learningAreasData.data,
    quizThemes: quizThemesData.data,
    quizSubThemes: quizSubThemesData.data,
    quizTypes: quizTypesData.data,
    quizSubTypes: quizSubTypesData.data,
    years: yearsData.data,
  };
}

export default {
  getAllQuizzesBypage,
  getAllLearnignAreas,
  getAllQuizzTypes,
  getAllQuizzSubTypes,
  getAllQuizThemes,
  getAllQuizSubThemes,
  getAllYears,
  getAllData,
};
