import React, { useState, useEffect } from "react";
import OpgService from "../services/labs.service";
import Loader from "./Loader";
import Table from "react-bootstrap/Table";
import {
  isEmpty,
  isNil,
  sortBy,
  prop,
  reject,
  equals,
  reverse,
  filter,
  pathEq,
} from "ramda";
import EventBus from "../common/EventBus";
import Select from "react-select";
import Button from "react-bootstrap/Button";

const Quizzes = () => {
  const [isLoading, setIsloading] = useState(false);
  const [quizzes, setQuizzes] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [pages, setPages] = useState(1);

  const [data, setData] = useState({
    learningAreas: [],
    quizThemes: [],
    quizSubThemes: [],
    quizTypes: [],
    quizSubTypes: [],
    years: [],
  });

  const [type, setType] = useState("");
  // const [subType, setSubType] = useState(0);
  const [theme, setTheme] = useState("");
  const [subtheme, setSubTheme] = useState("");
  const [year, setYear] = useState("");

  const gotoPage = (page, year, theme, subtheme, type) => {
    setIsloading(true);
    setCurPage(page);
    OpgService.getAllQuizzesBypage(page, prop("label", year), prop("value", theme), prop("value", subtheme), prop("value", type)).then(
      (resp) => {
        setIsloading(false);
        setPages(resp.data.pages);
        setQuizzes(resp.data);
      },
      (error) => {
        setData();
        if (error.resp && error.resp.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  const testType = (type) => {
    setType(isNil(type) ? 0 : type.value);
    gotoPage(1, year, theme, subtheme, type)
  };

  // const testSubType = (subType) => {
  //   setSubType(isNil(subType) ? 0 : subType.value);
  // };

  const testTheme = (theme) => {
    setTheme(isNil(theme) ? 0 : theme.value);
    gotoPage(1, year, theme, subtheme, type)
  };

  const testSubTheme = (subtheme) => {
    setSubTheme(isNil(subtheme) ? 0 : subtheme.value);
    gotoPage(1, year, theme, subtheme, type)
  };

  const testYear = (year) => {
    setYear(isNil(year) ? 0 : year.value);
    gotoPage(1, year, theme, subtheme, type)
  };

  const optionsYears = (item) => {
    const currentYear = new Date().getFullYear();
    let dataX = sortBy(
      prop("year"),
      item.map(function (c) {
        if (parseInt(c.year) <= currentYear + 1) {
          return { value: c.id, label: parseInt(c.year) };
        } else {
          return {};
        }
      })
    );

    return reverse(reject(equals({}))(dataX));
  };

  const options = (item) => {
    if (isEmpty(item)) {
      return [];
    }
    return sortBy(
      prop("label"),
      item.map(function (c) {
        return { value: c.id, label: c.description };
      })
    );
  };

  useEffect(() => {
    let canRun = true;
    const fetchData = async () => {
      if (canRun) {
        setIsloading(true);
        OpgService.getAllData().then(
          (resp) => {
            setIsloading(false);
            setData(resp);
            setPages(resp.quizzes.pages);
            setQuizzes(resp.quizzes);
          },
          (error) => {
            setData();
            if (error.resp && error.resp.status === 401) {
              EventBus.dispatch("logout");
            }
          }
        );
      }
    };

    // call the function
    fetchData().catch(console.error);
    return () => (canRun = false);
  }, []);

  if (!isNil(data) && !isEmpty(quizzes)) {
    let sortedOpgs = quizzes.data; // sortBy(path(["year", "year"]), quizzes.data);

    if (year > 0) {
      sortedOpgs = filter(pathEq(["year", "id"], parseInt(year)), sortedOpgs);
    }

    if (type > 0) {
      sortedOpgs = filter(
        pathEq(["quiz_type", "id"], parseInt(type)),
        sortedOpgs
      );
    }

    // if (subType > 0) {
    //   sortedOpgs = filter(
    //     pathEq(["quiz_sub_type", "id"], parseInt(subType)),
    //     sortedOpgs
    //   );
    // }

    if (theme > 0) {
      sortedOpgs = filter(pathEq(["theme", "id"], parseInt(theme)), sortedOpgs);
    }

    if (subtheme > 0) {
      sortedOpgs = filter(
        pathEq(["sub_theme", "id"], parseInt(subtheme)),
        sortedOpgs
      );
    }
    return (
      <>
        <div className="row">
          <div className="col-12 pt-2 pl-2 pb-2">
            <h2 className="txt-darkorange">Quizzes</h2>
          </div>
          <div className="col-12 pt-2 pl-2 pb-2">
            <h3>Aqui podes pesquisar e responder aos quizzes.</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-xs-6">
            <Select
              options={optionsYears(data.years)}
              placeholder="Ano"
              className="m-1"
              onChange={testYear}
              isClearable={true}
            />
          </div>

          <div className="col-md-3 col-xs-6">
            <Select
              options={options(data.quizTypes)}
              selectedValue={type}
              placeholder="Tipo Teste"
              className="m-1"
              isClearable={true}
              onChange={testType}
            />
          </div>
          {/* <div className="col-md-3 col-xs-6">
            <Select
              options={options(data.quizSubTypes)}
              placeholder="Sub Tipo Teste"
              className="m-1"
              isClearable={true}
              onChange={testSubType}
            />
          </div> */}

          <div className="col-md-3 col-xs-6">
            <Select
              options={options(data.quizThemes)}
              placeholder="Tema"
              className="m-1"
              isClearable={true}
              onChange={testTheme}
            />
          </div>
          {/* <div className="col-md-3 col-xs-6">
            <Select
              options={options(data.learningAreas)}
              placeholder="AE 1"
              className="form-control"
            />
          </div>
          <div className="col-md-3 col-xs-6">
            <Select
              options={options(data.learningAreas)}
              placeholder="AE 2"
              className="form-control"
            />
          </div> */}

          <div className="col-md-3 col-xs-6">
            <Select
              options={options(data.quizSubThemes)}
              placeholder="Sub Tema"
              className="m-1"
              onChange={testSubTheme}
              isClearable={true}
              styles={{
                placeholder: (base) => ({
                  ...base,
                  fontSize: "1em",
                  color: "Escolher...",
                  fontWeight: 400,
                }),
              }}
            />
          </div>
        </div>
        {/* <div className="row pt-3">
          <div className="col-md-3 col-xs-6">
            <Select
              options={optionsYears(data.years)}
              placeholder="Ano"
              className="form-control"
              onChange={testYear}
              isClearable={true}
            />
          </div>
          <div className="col-md-3 col-xs-6"></div>
          <div className="col-md-3 col-xs-6 pt-2 pl-3">
            <Button
              variant="btn btn-primary  btn-success w-100 shadow"
              onClick={doClean}
            >
              Limpar
            </Button>
          </div>
          <div className="col-md-3 col-xs-6 pt-2 pl-3">
            <Button
              variant="btn btn-primary  btn-success w-100 shadow"
              onClick={doSearch}
            >
              Procurar
            </Button>
          </div>
        </div> */}
        <div className="row">
          <div className="col-12 pt-3">
            { isLoading && <div className="d-flex justify-content-center pt-2 pb-2"><Loader isLoading={isLoading} /></div>}
            {!isEmpty(data.quizzes) && !isLoading && (
              <Table striped bordered hover size="sm" responsive>
                <thead>
                  <tr>
                    <th className="text-center">Ano</th>
                    <th className="text-center">Link Para o teste</th>
                    {/* <th className="text-center">Aprendizagem Essencial 1</th>
                    <th className="text-center">Aprendizagem Essencial 2</th> */}
                    <th className="text-center">Tipo Teste</th>
                    {/* <th className="text-center">Sub-Tipo Teste</th> */}
                    <th className="text-center">Tema</th>
                    <th className="text-center">Sub-Tema</th>
                  </tr>
                </thead>
                <tbody>
                  {!isEmpty(sortedOpgs) &&
                    sortedOpgs.map((item) => {
                      return (
                        <tr key={item.year + "-" + item.id}>
                          <td className="text-center align-middle">
                            {item.year.year}
                          </td>
                          <td className="text-center align-middle">
                            <a href={item.url} rel="noreferrer" target="_blank">
                              {item.description}
                            </a>
                          </td>
                          {/* <td className="text-center align-middle">
                            {item.la_1.small_description}
                          </td>
                          <td className="text-center align-middle">
                            {item.la_2.small_description}
                          </td> */}
                          <td className="text-center align-middle">
                            {item.quiz_type.description}
                          </td>
                          {/* <td className="text-center align-middle">
                            {item.quiz_sub_type.description}
                          </td> */}
                          <td className="text-center align-middle">
                            {item.theme.description}
                          </td>
                          <td className="text-center align-middle">
                            {item.sub_theme.description}
                          </td>
                        </tr>
                      );
                    })}
                  <tr key="total_count">
                    <td className="text-center align-middle" colSpan="6">
                      {sortedOpgs.length} Registos encontrados
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
          </div>
          <div className="row">
            <div className="col-6">
              <Button
                variant="btn btn-primary  btn-success w-100 shadow"
                onClick={() => gotoPage(curPage - 1, year, theme, subtheme, type)}
                disabled={curPage - 1 <= 0 ? true : false}
              >
                Página Anterior
              </Button>
            </div>
            <div className="col-6">
              <Button
                variant="btn btn-primary  btn-success w-100 shadow"
                onClick={() => gotoPage(curPage + 1, year, theme, subtheme, type)}
                disabled={curPage < pages ? false : true}
              >
                Página seguinte
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    if (isLoading) {
      return (
        <div className="d-flex justify-content-center">
          <Loader isLoading={isLoading} />
        </div>
      );
    } else {
      return <p>Sem dados para apresentar.</p>;
    }
  }
};

export default Quizzes;
