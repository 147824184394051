import React from "react";
import DOMPurify from "dompurify";
import { isNil } from "ramda";

const DynamicContent = (props) => {
  const { title = "", content = "", children } = props;
  return (
    <>
      <h2 className="txt-darkorange bold mb-1">{title}</h2>
      {!isNil(content) && (
        <div
          className="pt-2"
          style={{ textAlign: "justify" }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              content.replaceAll("<img", '<img class="img-fluid" '),
              {
                ALLOWED_ATTR: ["style", "class", "type", "href", "rel", "src", "target"],
                ALLOWED_TAGS: [
                  "p",
                  "img",
                  "br",
                  "u",
                  "i",
                  "em",
                  "b",
                  "strong",
                  "table",
                  "tbody",
                  "tr",
                  "td",
                  "a",
                ],
              }
            ),
          }}
        />
      )}
      {isNil(content) && <div className="pt-3">Sem conteúdo associado</div>}
      <div>{children}</div>
    </>
  );
};

export default DynamicContent;
