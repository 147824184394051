import React from "react";
import { Row, Col } from "react-bootstrap";

const WhoAreWe = () => {
  return (
    <Row>
      <Col md={12}>
        <h2 className="txt-darkorange bold mb-1">Quem somos</h2>{" "}
      </Col>
      <Col md={9} xs={12} style={{ textAlign: "justify" }} className="pt-2">
      O  Socgeol LABS é um local online de formação e de motivação dos alunos do ensino secundário para um melhor conhecimento do mundo “não vivo” que os envolve, ou seja, de motivação para as Geociências, isto é a Geologia, a Engª Geológica, a Geofísica e, porque não, a área do Ambiente. O “Ambiente” não é constituído apenas pela componente viva do nosso planeta, a sua “componente biológica”, mas é também constituído por todos os factores dinâmicos do planeta que podem condicionar essa componente: a atmosfera, a hidrosfera, a litosfera e, mais profundamente, a Geosfera.
      </Col>
      <Col md={3} xs={12} className="text-center">
        <img
          src="/logo.png"
          alt="Olimpiadas"
          className="img-fluid responsive"
        />
      </Col>
    </Row>
  );
};

export default WhoAreWe;
