/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import "./App.css";

import NavBar from "./components/NavBar";

import CarouselComponent from "./components/Carousel";
import Home from "./components/Home";
import Connection from "./components/Connection";
import WhoAreWe from "./components/WhoAreWe";
import Footer from "./components/Footer";
import DynamicPage from "./components/DynamicPage";
import ActualityDetail from "./components/ActualityDetail";
import Quizzes from "./components/Quizzes";

import { filter, propEq, isEmpty } from "ramda";

import DynamicPagesService from "./services/dynamic-page.service";
import ActualitiesService from "./services/actualities.service";

import { history } from "./helpers/history";

import EventBus from "./common/EventBus";

const App = () => {
  const withRouter = (Component) => {
    const Wrapper = (props) => {
      const history = useNavigate();
      return <Component history={history} {...props} />;
    };
    return Wrapper;
  };

  function HeaderView() {
    const showCar = ["/", "who_are_we", "/history", "/tests", "/ieso"];
    let location = useLocation();
    return (
      showCar.includes(location.pathname) && (
        <CarouselComponent topCarItems={topCarItems} />
      )
    );
  }

  function _ScrollToTop(props) {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    return props.children;
  }

  const [dynamicPages, setDynamicPages] = useState([]);
  const [dynamicFooter, setDynamicFooter] = useState([]);

  const [actualities, setActualities] = useState([]);
  const [linkTypes, setLinkTypes] = useState([]);

  let [topCarItems, setTopCarItems] = useState([]);
  let [lowerCarItems, setLowerCarItems] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    ActualitiesService.getAllActualities().then(
      (response) => {
        setActualities(response.data.news);
        setLinkTypes(response.data.links);
        setTopCarItems(
          response.data.news_c
            .concat(isEmpty(response.data.dp_c) ? [] : response.data.dp_c)
            .concat(isEmpty(response.data.photos) ? [] : response.data.photos)
        );
        setLowerCarItems(
          response.data.news_h.concat(
            isEmpty(response.data.dp_h) ? [] : response.data.dp_h
          )
        );
        setIsLoading(false);
      },
      (error) => {
        setActualities([]);
        setIsLoading(false);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );

    DynamicPagesService.getDynamicPages().then(
      (response) => {
        setDynamicPages(response.data);
        setDynamicFooter(filter(propEq("is_footer", true), response.data));
      },
      (error) => {
        setDynamicPages(undefined);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  useEffect(() => {}, []);

  const ScrollToTop = withRouter(_ScrollToTop);

  return (
    <BrowserRouter history={history}>
      <div>
        <div className="container mt-5 pt-10">
          <ScrollToTop>
            <NavBar dynamicPages={dynamicPages} />

            <main className="mt-5">
              <div className="container bg-light pt-3">
                <HeaderView />
                <div className="mt-3">
                  <Routes>
                    <Route
                      exact
                      path="/"
                      element={
                        <Home
                          actualities={actualities}
                          dynamicPages={dynamicPages}
                          lowerCarItems={lowerCarItems}
                          topCarItems={topCarItems}
                          isLoading={isLoading}
                        />
                      }
                    />

                    <Route
                      exact
                      path="/pages/:pageId"
                      element={<DynamicPage />}
                    />

                    <Route
                      exact
                      path="/actualities/:actualityId"
                      element={<ActualityDetail />}
                    />

                    <Route
                      exact
                      path="/links_pt"
                      element={
                        <Connection national={true} linkTypes={linkTypes} />
                      }
                    />
                    <Route
                      exact
                      path="/links_int"
                      element={
                        <Connection national={false} linkTypes={linkTypes} />
                      }
                    />
                    <Route exact path="/who_are_we" element={<WhoAreWe />} />
                    <Route exact path="/quizzes" element={<Quizzes />} />
                    <Route
                      exact
                      path="/actuality_lab/:actualityId"
                      element={<ActualityDetail />}
                    />
                  </Routes>
                  <div id="scroller"></div>
                </div>
                <Footer dynamicFooter={dynamicFooter} />
              </div>
            </main>
          </ScrollToTop>
        </div>
        {/* <AuthVerify logOut={logOut}/> */}
      </div>
    </BrowserRouter>
  );
};

export default App;
